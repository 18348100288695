<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm6 md2 pa-5 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Seller View</span
            >
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md2 pa-5 text-right>
            <v-dialog
              :retain-focus="false"
              v-model="dialogSellerPassword"
              persistent
              max-width="600px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="green" v-bind="attrs" v-on="on"
                  ><span style="font-family: poppinsmedium"
                    >Change Password</span
                  >
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-card-title>
                    <span
                      style="
                        font-family: poppinsmedium;
                        color: #000;
                        font-size: 20px;
                      "
                      >Change Password</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row style="font-family: poppinsmedium">
                        <v-col cols="12">
                          <v-text-field
                            v-model="passwordSeller"
                            label="Change Password"
                            outlined
                            dense
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogSellerPassword = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="changePasswordSeller()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs12 sm6 md2 pa-5 text-right>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="600px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="green" v-bind="attrs" v-on="on"
                  ><span style="font-family: poppinsmedium"
                    >Add Store User</span
                  >
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-card-title>
                    <span
                      style="
                        font-family: poppinsmedium;
                        color: #000;
                        font-size: 20px;
                      "
                      >Add Store User</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row style="font-family: poppinsmedium">
                        <v-col cols="12">
                          <v-text-field
                            v-model="store.name"
                            label="Name"
                            outlined
                            dense
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="store.phone"
                            label="Phone"
                            outlined
                            dense
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="store.email"
                            label="Email"
                            outlined
                            dense
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="store.password"
                            label="Password"
                            outlined
                            dense
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="store.address"
                            label="Address"
                            outlined
                            dense
                            :rules="[rules.required]"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!addcat"
                      @click="add()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs12 sm6 md2 pa-5 text-right>
            <v-btn
              outlined
              color="green"
              :to="'/products?id=' + $route.query.id"
              ><span style="font-family: poppinsmedium">Product List</span>
            </v-btn>
          </v-flex>
          <v-flex xs12 sm6 md2 pa-5 text-right>
            <v-btn
              outlined
              color="green"
              :to="'/purchaseList?id=' + $route.query.id"
              ><span style="font-family: poppinsmedium">Purchase List</span>
            </v-btn>
          </v-flex>
          <v-flex xs12 sm6 md2 pa-5 text-right>
            <v-btn
              outlined
              color="green"
              :to="'/orderReportSeller?id=' + $route.query.id"
              ><span style="font-family: poppinsmedium">Order Report</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout wrap px-5>
          <v-flex xs12 sm12>
            <v-layout wrap>
              <v-flex
                xs12
                style="font-family: poppinssemibold; font-size: 20px"
              >
                {{ shop.shopName }}</v-flex
              >
            </v-layout>
            <v-layout wrap py-5>
              <v-flex xs12 sm12>
                <span class="titleText">Description</span>
              </v-flex>
              <v-flex xs12 sm12>
                <span class="contentText" v-html="shop.description"></span>
              </v-flex>
              <v-flex xs12 sm12>
                <span class="titleText">Address</span>
              </v-flex>
              <v-flex xs12 sm12>
                <span class="contentText"
                  >{{ shop.address.name }}, {{ shop.address.place }},
                  {{ shop.address.district }}</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <span style="font-family: poppinssemibold"
                      >Contact Person -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6>
                    <span class="contentText">{{ shop.contactPerson }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <span style="font-family: poppinssemibold"
                      >Contact Number -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6>
                    <span class="contentText">{{ shop.contactNumber }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <span style="font-family: poppinssemibold">Email -</span>
                  </v-flex>
                  <v-flex xs12 sm6 md9>
                    <span class="contentText">{{ shop.email }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4 pt-5>
                <v-layout wrap>
                  <v-flex xs12 sm6>
                    <span style="font-family: poppinssemibold">GST Number -</span>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <span class="contentText">{{ shop.gstNumber }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="titleText" py-5>Social Media Links</v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <span style="font-family: poppinssemibold">Facebook -</span>
                  </v-flex>
                  <v-flex xs12 sm6 md9>
                    <span class="contentText">{{
                      shop.socialmedialinks.facebook
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <span style="font-family: poppinssemibold"
                      >Instagram -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 md9>
                    <span class="contentText">{{
                      shop.socialmedialinks.instagram
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <span style="font-family: poppinssemibold">Whatsapp -</span>
                  </v-flex>
                  <v-flex xs12 sm6 md9>
                    <span class="contentText">{{
                      shop.socialmedialinks.whatsapp
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 class="titleText" py-5>Bank Details</v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4>
                    <span style="font-family: poppinssemibold"
                      >Bank Name -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 md8>
                    <span class="contentText">{{ shop.bankDetails.bank }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md4>
                    <span style="font-family: poppinssemibold"
                      >Bank Branch -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 md8>
                    <span class="contentText">{{
                      shop.bankDetails.branch
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <span style="font-family: poppinssemibold"
                      >Account Number -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <span class="contentText">{{
                      shop.bankDetails.accountNo
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4 pt-5>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <span style="font-family: poppinssemibold"
                      >Account Holder -</span
                    >
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <span class="contentText">{{
                      shop.bankDetails.accountHolder
                    }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm6 md4 pt-5>
                <v-layout wrap>
                  <v-flex xs12 sm6 md3>
                    <span style="font-family: poppinssemibold">IFSC -</span>
                  </v-flex>
                  <v-flex xs12 sm6 md9 text-left>
                    <span class="contentText">{{ shop.bankDetails.ifsc }}</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap px-5 pb-10>
          <v-flex xs12 sm12 py-5 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Store Users</span
            >
          </v-flex>
          <v-flex xs12>
            <v-data-table
              :headers="headers"
              :items="storeUsers"
              hide-default-footer
              class="elevation-1"
              style="font-family: poppinsmedium"
            >
              <template v-slot:[`item.role`]="{ item }">
                <span v-if="item.role == 'StoreUser'">Store User</span>
                <span v-else>{{ item.role }}</span>
              </template>
              <template v-slot:[`item._id`]="{ item }">
                <v-icon small class="mr-2" @click="editcat(item)">
                  mdi-pencil
                </v-icon>
                <v-dialog v-model="item.delete" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <v-card>
                    <v-card-title style="font-family: poppinsmedium"
                      >Are you sure you want to delete this store
                      user?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="item.delete = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItem(item)"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  small
                  outlined
                  color="green"
                  @click="(dialogChange = true), (passId = item._id)"
                  >Change Password</v-btn
                >
              </template>
              <template v-slot:top>
                <v-dialog
                  v-model="editdialog"
                  :retain-focus="false"
                  max-width="600px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="headline">Edit Store User </span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row style="font-family: poppinsmedium">
                          <v-col cols="12">
                            <v-text-field
                              v-model="editingitem.name"
                              label="Name"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editingitem.phone"
                              label="Phone"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editingitem.email"
                              label="Email"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editingitem.address"
                              label="Address"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="editdialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="edit()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <v-dialog
          v-model="dialogChange"
          :retain-focus="false"
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Change Password </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row style="font-family: poppinsmedium">
                  <v-col cols="12">
                    <v-text-field
                      v-model="password"
                      label="New Password"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogChange = false">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="changePassword()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      addcat: false,
      dialog: false,
      editdialog: false,
      dialogChange: false,
      dialogSellerPassword:false,
      editingitem: "",
      passId: "",
      shop: {},
      store: {
        name: "",
        phone: "",
        email: "",
        password: "",
        address: "",
      },
      password: "",
      passwordSeller: "",
      storeUsers: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Role", value: "role" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Address", value: "address" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rule: {
        required: (value) => !!value || "Required.",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        minPhone: (value) => value.length == 10 || "Invalid Phone",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/seller",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.shop = response.data.data;
          this.storeUsers = response.data.users;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.store.sellerId = this.$route.query.id;
      axios({
        url: "/seller/addUser",
        method: "POST",
        data: this.store,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["phone"] = this.editingitem.phone;
      data["email"] = this.editingitem.email;
      data["address"] = this.editingitem.address;
      axios({
        url: "/seller/editUser",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/seller/userRemove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changePassword() {
      var data = {};
      data["id"] = this.passId;
      data["password"] = this.password;
      axios({
        url: "/seller/passwordEdit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialogChange = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changePasswordSeller() {
      var data = {};
      data["id"] = this.$route.query.id;
      data["password"] = this.passwordSeller;
      axios({
        url: "/seller/passwordEdit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.dialogSellerPassword = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.titleText {
  font-family: poppinssemibold;
  font-size: 18px;
}
.contentText {
  font-family: poppinsmedium;
  font-size: 15px;
}
</style>